<script setup lang="ts">
interface Props {
  shadow?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  shadow: true,
});

const src = computed(() => {
  if (props.shadow) return '/felix/logo.svg';
  return '/felix/logo-no-shadow.svg';
});
</script>

<template>
  <NuxtImg
    :src="src"
    alt="Felix Logo"
  />
</template>

import type { Job } from "@/dto/job/job";
import type {
  FelixConversation,
  FelixMessageResponseData,
  FelixSuccess,
} from "@/types/felix";
import { useFetchStateStore } from "@/store/fetch-state";

export const useFelixStore = defineStore("felix", () => {
  const chatOpen = ref(false);
  const isLoading = ref(false);
  const felixError = ref<{ message: string; showInChat: boolean } | null>(null);
  const conversations = ref<FelixConversation[]>([]);
  const conversationMap = ref<Map<string, string>>(new Map());
  const selectedConversationId = ref<string>();
  const hiddenRoutes = ref<string[]>([
    ROUTES.dashboardJobsCreate,
  ]);

  const fetchStateStore = useFetchStateStore();
  const fetching = computed(() => fetchStateStore.fetching["felix_conversations"]);

  const hasUnreadMessages = computed(() => {
    return conversations.value.some((conversation) =>
      conversation.messages.some(
        (msg) => msg.role === FELIX_MESSAGE_ROLE.assistant && !msg.read_at && !msg.is_loading
      )
    );
  });

  const toggleChat = () => {
    chatOpen.value = !chatOpen.value;
  };

  const openChat = () => {
    chatOpen.value = true;
  };

  const closeChat = () => {
    chatOpen.value = false;
  };

  const withFelixCall = async <T>(
    apiCall: () => Promise<T>,
    error: { message: string; throw?: boolean; showInChat?: boolean }
  ): Promise<T | undefined> => {
    isLoading.value = true;
    felixError.value = null;

    try {
      return await apiCall();
    } catch (e) {
      if (e instanceof Error) {
        felixError.value = { 
          message: error.message ?? e.message,
          showInChat: error.showInChat ?? false
        };
      } else {
        felixError.value = { 
          message: error.message ?? "An unexpected error occurred",
          showInChat: error.showInChat ?? false
        };
      }
      if (error.throw) throw e;
    } finally {
      isLoading.value = false;
    }
  };

  const fetchConversations = async () => {
    return await fetchStateStore.fetchOnce("felix_conversations", async () => {
      return withFelixCall(async () => {
        const result = await $api<FelixConversation[]>(API_ROUTES.felix.conversations.index);
        conversations.value = result;

        conversationMap.value.clear();
        result.forEach((conversation) => {
          if (conversation.unique_identifier) {
            conversationMap.value.set(conversation.unique_identifier, conversation.id);
          }
        });

        return conversations.value;
      }, { message: ERROR_MESSAGES.felix_error_fetching_conversations, throw: true });
    });
  };

  const findConversationByIdentifier = (uniqueIdentifier: string) => {
    const conversationId = conversationMap.value.get(uniqueIdentifier);
    if (!conversationId) return null;
    return conversations.value.find((c) => c.id === conversationId);
  };

  const createTempConversationId = () => {
    return `temp-${Date.now()}`;
  };

  const createLocalConversation = (userMessage: string, uniqueIdentifier: string) => {
    const tempId = createTempConversationId();
    const timestamp = new Date().toISOString();

    const conversation: FelixConversation = {
      id: tempId,
      user_type: "external",
      messages: [
        {
          content: userMessage,
          role: "user",
          sequence: 0,
          timestamp,
        },
        {
          content: "",
          role: "assistant",
          sequence: 1,
          timestamp,
          is_loading: true,
        },
      ],
      is_active: true,
      created_at: timestamp,
      updated_at: timestamp,
      last_message_at: timestamp,
      unique_identifier: uniqueIdentifier,
    };

    conversations.value.push(conversation);
    conversationMap.value.set(uniqueIdentifier, tempId);
    selectedConversationId.value = tempId;
    return conversation;
  };

  const updateConversationWithResponse = (
    response: FelixMessageResponseData,
    uniqueIdentifier: string,
    userPrompt: string
  ) => {
    const { conversationId, response: felixResponse, sequence } = response;
    const timestamp = new Date().toISOString();

    const existingConversation = conversations.value.find((c) => c.unique_identifier === uniqueIdentifier);

    const shouldMarkAsRead = chatOpen.value && selectedConversationId.value === conversationId;
    const readAt = shouldMarkAsRead ? timestamp : undefined;
    if (existingConversation) {
      if (existingConversation.id.startsWith("temp-")) {
        existingConversation.id = conversationId;
        conversationMap.value.set(uniqueIdentifier, conversationId);
        selectedConversationId.value = conversationId;
      }

      const loadingMessageIndex = existingConversation.messages.findIndex((m) => m.is_loading);

      if (loadingMessageIndex !== -1) {
        existingConversation.messages[loadingMessageIndex] = {
          content: felixResponse,
          role: "assistant",
          sequence,
          timestamp,
          read_at: readAt,
        };
      } else {
        existingConversation.messages.push({
          content: felixResponse,
          role: "assistant",
          sequence,
          timestamp,
          read_at: readAt,
        });
      }

      existingConversation.updated_at = timestamp;
      existingConversation.last_message_at = timestamp;
      if (shouldMarkAsRead) {
        existingConversation.last_read_at = timestamp;
        markConversationAsRead(conversationId);
      }
    } else {
      const newConversation: FelixConversation = {
        id: conversationId,
        user_type: FELIX_USER_TYPE.external,
        messages: [
          {
            content: userPrompt,
            role: FELIX_MESSAGE_ROLE.user,
            sequence: sequence - 1,
            timestamp,
          },
          {
            content: felixResponse,
            role: FELIX_MESSAGE_ROLE.assistant,
            sequence,
            timestamp,
            read_at: readAt,
          },
        ],
        is_active: true,
        created_at: timestamp,
        updated_at: timestamp,
        last_message_at: timestamp,
        last_read_at: readAt,
        unique_identifier: uniqueIdentifier,
      };

      conversations.value.push(newConversation);
      conversationMap.value.set(uniqueIdentifier, conversationId);
      selectedConversationId.value = conversationId;

      if (shouldMarkAsRead) markConversationAsRead(conversationId);
    }
  };

  const generateJobDetails = async ({ job, additionalPrompt }: { job: Job; additionalPrompt?: string }) => {
    return withFelixCall(
      () =>
        $api<string>(API_ROUTES.felix.jobDetails, {
          method: "POST",
          body: { job, additionalPrompt },
        }),
      { message: ERROR_MESSAGES.felix_error_generating_job_details, throw: true }
    );
  };

  const processJobConversation = async ({ job, userPrompt }: { job: Job; userPrompt: string }) => {
    const uniqueIdentifier = getJobUniqueIdentifier(job.id);
    let existingConversation = conversations.value.find((c) => c.unique_identifier === uniqueIdentifier);

    // Create a new conversation if none exists for this job
    if (!existingConversation) {
      existingConversation = createLocalConversation(userPrompt, uniqueIdentifier);
    } else {
      // Add loading message to existing conversation
      const timestamp = new Date().toISOString();
      existingConversation.messages.push(
        {
          content: userPrompt,
          role: "user",
          sequence: existingConversation.messages.length,
          timestamp,
        },
        {
          content: "",
          role: "assistant",
          sequence: existingConversation.messages.length + 1,
          timestamp,
          is_loading: true,
        }
      );
    }

    selectedConversationId.value = existingConversation.id;

    const res = await withFelixCall(async () => {
      try {
        const response = await $api<FelixMessageResponseData>(API_ROUTES.felix.jobFeedback, {
          method: "POST",
          body: { job, userPrompt, uniqueIdentifier },
        });
        updateConversationWithResponse(response, uniqueIdentifier, userPrompt);
        return response;
      } catch (error) {
        if (!existingConversation) throw error; 
          
        const loadingMessageIndex = existingConversation.messages.findIndex((m) => m.is_loading);
        if (loadingMessageIndex !== -1) existingConversation.messages.splice(loadingMessageIndex, 1);
          
        throw error;
      }
    }, { 
      message: ERROR_MESSAGES.felix_error_generating_job_feedback, 
      throw: false,
      showInChat: true
    });

    return res?.response;
  };

  const getJobUniqueIdentifier = (jobId: number) => {
    return `job-${jobId}`;
  };

  const deleteConversation = async (conversationId: string) => {
    return withFelixCall(
      async () => {
        conversations.value = conversations.value.filter((c) => c.id !== conversationId);

        for (const [key, value] of conversationMap.value) {
          if (value === conversationId) {
            conversationMap.value.delete(key);
            break;
          }
        }

        await $api<FelixSuccess>(`${API_ROUTES.felix.conversations.index}/${conversationId}`, {
          method: "DELETE",
        });
      },
      { message: ERROR_MESSAGES.felix_error_deleting_conversation, throw: true }
    );
  };

  const markConversationAsRead = async (conversationId: string) => {
    if (conversationId.startsWith("temp-")) return;

    setTimeout(() => {
      const now = new Date().toISOString();
      conversations.value = conversations.value.map((conversation) => {
        if (conversation.id === conversationId) {
          return {
            ...conversation,
            last_read_at: now,
            messages: conversation.messages.map((msg) => {
              if (msg.role === FELIX_MESSAGE_ROLE.assistant && !msg.is_loading) {
                return { ...msg, read_at: now };
              }
              return msg;
            }),
          };
        }
        return conversation;
      });
    }, 1000);

    await $api<FelixSuccess>(`${API_ROUTES.felix.conversations.index}/${conversationId}`, {
      method: "PATCH",
    });
  };

  return {
    chatOpen,
    isLoading,
    fetching,
    felixError,
    conversations,
    selectedConversationId,
    hasUnreadMessages,
    toggleChat,
    openChat,
    closeChat,
    fetchConversations,
    generateJobDetails,
    processJobConversation,
    findConversationByIdentifier,
    getJobUniqueIdentifier,
    deleteConversation,
    markConversationAsRead,
    hiddenRoutes,
  };
});
